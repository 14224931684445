<template>
  <iframe-integration
    :app="app"
    :path="path"
    :params="params"
  ></iframe-integration>
</template>

<script>
import IframeIntegration from '@gid/vue-common/components/IframeIntegration';
import { mapGetters } from 'vuex';

export default {
  components: {
    IframeIntegration,
  },
  data() {
    return {
      app: 'rating',
      path: '/admin',
    };
  },
  computed: {
    ...mapGetters(['access_token']),
    params() {
      return new URLSearchParams({ gid_bearer: this.access_token });
    },
  },
};
</script>
